import logo from "./logo.svg";
import "./App.css";

function App() {
  const data = {
    E75662M2N936607: "HL-L5100DN",
    E75662M2N936620: "HL-L5200DN",
  };
  const loadProductBySN = () => {
    const element = document.querySelector(
      'input[ng-model="ParamWarrantyRegistration.modelRegisForm.serialNumber"]'
    );
    const value = data[element.value];
    const parentOption = document.querySelector(
      `select[ng-model="ParamWarrantyRegistration.modelRegisForm.productId"]`
    );
    if (parentOption) {
      if (value) {
        const option = parentOption.querySelector(`option[value="?"]`);
        if (option) {
          option.value = 0;
          option.text = value;
        }
      } else {
        const option = parentOption.querySelector(`option[value="0"]`);
        if (option) {
          option.value = "?";
          option.text = "";
        }
      }
    }
  };

  return (
    <div className="swMain">
      <div id="step-1" ng-show="steps.step1.open" className="">
        <div className="row">
          <div className="col-md-12">
            <fieldset>
              <legend className="ng-binding">Thông tin Sản phẩm </legend>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="ng-binding">
                      Số Sê-ri (*) <span className="symbol required"></span>
                      <a
                        tabIndex="0"
                        data-toggle="tooltip"
                        id="popover"
                        data-trigger="focus"
                        data-content=""
                        data-original-title=""
                        title=""
                      >
                        <i className="fa fa-question-circle"></i>
                      </a>
                    </label>
                    <label className="alert-danger ng-binding">
                      Vui lòng xem Số Sê-ri ở phía sau máy.
                    </label>
                    <input
                      type="text"
                      no-special-char=""
                      ng-model="ParamWarrantyRegistration.modelRegisForm.serialNumber"
                      className="form-control ng-pristine ng-valid"
                      maxLength="25"
                      ng-trim="false"
                      onKeyUp={loadProductBySN}
                      onBlur={loadProductBySN}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <span ng-show="flag" className="">
                      <label className="ng-binding">Tên Máy (*)</label>
                      <select
                        className="form-control ng-pristine ng-valid"
                        ng-disabled="true"
                        ng-model="ParamWarrantyRegistration.modelRegisForm.productId"
                        ng-options="item as item.name for item in optProduct"
                        disabled="disabled"
                      >
                        <option value="?"></option>
                      </select>
                    </span>
                    {/* <span ng-show="!flag" className="ng-binding ng-hide">
                      <label className="ng-binding">Tên Máy (*)</label>
                      <br />
                      <br />
                      Số sê ri không hợp lệ. Vui lòng liên hệ Trung tâm Giải đáp
                      Brother. Nhấn vào{" "}
                      <a
                        href="http://welcome.brother.com/vn-vi/contact-us.html"
                        target="_blank"
                        className="ng-binding"
                      >
                        đây
                      </a>
                    </span> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <label className="ng-binding">
                      Ngày Mua hàng (DD/MM/YYYY){" "}
                      <span className="symbol required"></span>
                    </label>
                    <div className="input-group date" id="dtOfPurchase">
                      <input
                        type="text"
                        className="form-control ng-pristine ng-valid"
                        ng-model="ParamWarrantyRegistration.modelRegisForm.dateOfPurchase"
                        id="dtOfPurchaseInput"
                      />
                      <span className="input-group-addon">
                        <span className="glyphicon glyphicon-calendar"></span>
                      </span>
                    </div>
                    <br />
                    {/* <div className="form-group">
                      <label className="ng-binding">Bằng chứng Mua hàng</label>
                      <br />
                      <button
                        className="btn btn-success ng-binding"
                        type="button"
                        ng-click="AddInputFile()"
                      >
                        Đính kèm
                      </button>
                      <form
                        className="form form-inline ng-pristine ng-valid"
                        action="/BICV/ascportal/uploadProofOfPurchase"
                        method="POST"
                        enctype="multipart/form-data"
                        id="uploadFile"
                      >
                        <input type="hidden" name="WarrantyId" id="WarrantyId" />
                        <input
                          type="hidden"
                          name="TempMessage"
                          id="TempMessage"
                        />
                        <input
                          type="hidden"
                          name="StatusWarranty"
                          id="StatusWarranty"
                        />
                        <br />
                        <div
                          className="alert-danger ng-binding ng-hide"
                          ng-show="InputFiles.length > 0"
                        >
                          Dung lượng tập tin tối đa là 3 MB
                        </div>
                        ngRepeat: n in InputFiles
                      </form>
                    </div> */}
                    {/* <input
                      type="text"
                      ng-model="ParamWarrantyRegistration.modelRegisForm.dateOfPurchase"
                      disabled
                      className="form-control"
                    /> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="ng-binding">Mục đích sử dụng</label>
                    <select
                      defaultValue={"0"}
                      className="form-control ng-pristine ng-valid"
                      ng-model="ParamWarrantyRegistration.modelRegisForm.purpose"
                      ng-options="item.value as item.key for item in optPurpose"
                    >
                      <option value="" className=""></option>
                      <option value="0">Home Use</option>
                      <option value="1">Office Use</option>
                    </select>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div
                    className="form-group ng-hide"
                    ng-show="ParamWarrantyRegistration.modelRegisForm.purpose == 2"
                  >
                    <label className="ng-binding">
                      Tên Công ty{" "}
                      <span
                        className="symbol required"
                        ng-hide="OrgCRM == 'bctl' || OrgCRM =='bctleng'"
                      ></span>
                    </label>
                    <input
                      type="text"
                      ng-model="ParamWarrantyRegistration.modelRegisForm.companyName"
                      className="form-control ng-pristine ng-valid"
                    />
                  </div>
                </div> */}
              </div>
            </fieldset>
            <div className="form-group">
              <button
                className="btn btn-primary btn-o next-step btn-wide pull-right ng-binding"
                ng-click="validateSteps(1);"
              >
                Tiếp <i className="fa fa-arrow-circle-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
